import * as React from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'

interface QueryData{
  schools: {
    nodes: {
      name: string
      slug: string
    }[]
  }
}

const IndexPage = () => (
  <div>
    <h1>Ed-IT Solutions Home Pages</h1>
    <StaticQuery
      query={graphql`
        query IndexPageQuery{
          schools: allDirectusHomepage{
            nodes{
              name
              slug
            }
          }
        }
      `}
      render={(data: QueryData) => (
        <ul>
          {data.schools.nodes.map((node, i) => (
            <li key={i}>
              <Link to={`/schools/${node.slug}`}>{node.slug}</Link>
            </li>
          ))}
        </ul>
      )}
    />
  </div>
)

export default IndexPage